@import '../../global/vars';

.user-menu {
  display: flex;
  align-items: center;

  li {
    color: #fff;
  }

  &__avatar {
    margin: 10px 0;
  }

  &__messageIcon {
    margin-right: 0;
    padding: 0;
    padding-top: 2px;
    width: 22px;
  }

  &__btn {
    background: none;
    cursor: pointer;
    border: none;
    padding-right: 0;
  }

  .profile-container {
    position: relative;
  }
  
  .unreadMessageBadge {
    position: absolute;
    top: -5px;
    left: -5px;
    background-color: $qip-coral;
    color: $ui-white;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 10px;
  }

  .adminLabel {
    position: absolute;
    bottom: -5px;
    left: 50%;
    background-color: $qip-coral;
    color: white;
    border-radius: 30px;
    padding: 2px 4px;
    font-size: 7px;
    font-weight: bold;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}