@import '../../global/vars';

.balanceAvailableDetail {
  .raw {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    font-size: 1.2em;
    border-bottom: 1px solid $ui-grey-light;
    color: $qip-blue-light;

    &.light {
      color: $qip-blue-lighter;
    }

    &.total {
      border-top: 1px solid $ui-grey-light;
      margin-top: 40px;
    }
  }
}

.aboutFees {
  margin-top: 20px;
  align-items: center;
  text-align: center;
  a {
    color: $qip-coral;
    text-decoration: underline;
  }
}

.withdrawInformation {
  display: flex;
  flex-direction: column;
  align-items: center
}