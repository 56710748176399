@import '../../../global/vars';

.profile-edit .profile-form-edit {
  margin: 30px 20px 0;
  h5 {
    width: calc(100% + 40px);
    margin-left: -20px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;
    color: $qip-blue-light;
    border-bottom: 1px solid $ui-grey-light;
  }
}

.profile-edit .profile-form-edit .btnUpdate {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: $qip-coral;
  border-radius: 40px;

  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: $ui-background-light;


  &:hover {
    outline: none;
  }
}

.profile-edit .profile-form-edit .btnCancel {
  height: 2.2rem;
  border-radius: 0.5rem;
  font-size: 1.2em;
  background: $usefy-white;
  width: 100%;
  margin-top: 1rem;
  border: 1px solid $usefy-red;

  &:hover {
    outline: none;
  }
}

.field.photoURL {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 16px;
  border-bottom: 1px solid $ui-grey-light;
  
  label {
    margin-bottom: 0;

  }
  .uploadImage {
    width: 50px;
  }
  
  .error-message {
    flex-basis: 100%
  }
}
