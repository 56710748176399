@import '../../../global/vars';
@import '../Eqipo';

.buttonsContainerNewEqipoReview {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LineBlurNotUserProfile {
  border: 2px solid $qip-soft-blue;
  width: 100%;
}

.newEqipoReviewTitle {
  margin-top: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 100%;
  display: flex;
  align-items: flex-end;
  letter-spacing: -1px;
  text-transform: uppercase;
  color: $qip-blue-light;
  overflow: hidden;
  text-overflow: ellipsis;
}
