@import '../../global/vars';

.signin {
  padding: 40px 20px;
  padding-top: 48px;
  &__provider.button.is-primary {
    border-width: 2px;
    color: $usefy-black;
    font-weight: bold;
    padding: 0;
    &:hover {
      background-color: #FFF;
      color: $usefy-black;
    }
  }

  .titleContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    >h1 {
      width: 192px;
      margin: 0 !important;
      font-style: normal;
      font-weight: 900;
      font-size: 31px;
      line-height: 37px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      letter-spacing: -1px;
      text-transform: uppercase;
      color: $qip-blue-light;
    }

  }
  p {
    color: $usefy-black;
    font-weight: 600;
  }
  &__provider-logo {
    height: 100%;
    padding: 0;
    margin: 0;
    height: 2em;
    width: auto;
    height: 100%;
    padding: 0;
    margin: 0;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    img {
      height: 2em;
      width: auto;
    }
  }
  .submit {
    margin-top: 2em;
  }
  .caption {
    font-weight: 600;
    margin: 15px 4px;
    display: block;
  }
}

.sign-up-link {
  display: block;
  margin: 1.5em 0;
  font-weight: bold;
  text-align: center;

  &.button.is-white {
    color: $usefy-blue-light;
  }
}

.sign-up {
  padding: 0 1em;
  &__header .image{
    margin-top: 3em;
    margin-bottom: 2em;
  }
}

.signup-form {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__content {
    // flex-grow: 1;
    padding: 2em 1em;
    // display: flex;
    // flex-direction: column;
    // flex: 1;
  }

  &__submit {
    margin-top: 14px;
  }
}


.resetPassword {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: $qip-blue-light;
}

.loginButton {
  background: $qip-coral;
  border-radius: 40px;
  width: 100%;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $ui-background-light;
  margin-top: 15px;
}
.signUpButton {
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $qip-coral;
  margin-top: 15px;
}

.alignCenter  {
  display: flex;
  align-items: center;
  justify-content: center;
}

.OrSignIn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  > span {
    position: absolute;
    z-index: 1;
    width: 47px;
    height: 23px;
    background: $ui-white;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
  }
  > hr {
    width: 100vw;
    // margin-left: -42px;
    border: 1px solid #E0E0E0;
  }
}
