@import '../../global/vars';

.footer {
  width: 100%;
  padding: 20px !important;
  background: $ui-white !important;
  .footerTopLine {
    margin-bottom: 24px;
    height: 1px;
    background-color: $ui-grey-light;
    width: 100%;
  }
  .footer_menu {
    display: flex;
    flex-wrap: wrap;
    .menu_item {
      min-width: 50%;
      margin: 7.5px 0;
      max-width: 50%;
      padding-right: 15px;
      p {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: $qip-blue-light;
        margin-bottom: 8px;
      }

      ul {
        li {
          a {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: $qip-blue-light;
          }
        }
      }
    }
  }

  .footer-bottom {
    margin-top: 20px;
    > strong {
      font-style: italic;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    color: $qip-blue-light;
    }
    > hr {
      margin: 8px 0;
      height: 1px;
      background: $ui-grey-light;
      width: 100%;
    }

    .menu__social {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: $qip-coral-light;
      display: flex;
      width: 122px;
      >div {
        margin: 0 4px;
      }
      > a {
        font-size: 14px;
        line-height: 140%;
        color: $qip-coral-light;
      }
    }
  }
}


.copyRight {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 16px;
  > small {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 18px;
    color: $qip-blue-light;
  }
  > img {
    height: 16px;
    width: 32.84px;
  }
}
