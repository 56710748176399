@import '../../global/vars';

.accordion {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;

  &-item {
    border: 1px solid $ui-grey-light;
    margin-bottom: 1rem;
    overflow: hidden;
    background: $ui-white;
    transition: all 0.3s ease;

    &.completed {
      border-color: $qip-green;

      .accordion-icon {
        background-color: $qip-green;
        color: $ui-white;
      }

      .accordion-status {
        color: $qip-green;
      }
    }

    &.open {
      .accordion-content {
        max-height: 1000px;
        padding: 1rem;
      }
    }
  }

  &-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background: $ui-white;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: $ui-background;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-wrap: nowrap;

    h3 {
      margin: 0;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  &-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $ui-grey-light;
    color: $ui-grey;
    font-size: 1rem;
    transition: all 0.2s ease;
  }

  &-status {
    font-size: 0.875rem;
    color: $ui-grey;
  }

  &-content {
    max-height: 0;
    padding: 0 1rem;
    overflow: hidden;
    transition: all 0.3s ease;
  }
}
