@import '../../../global/vars';

@mixin img-box-style {
  box-sizing: border-box;
  border-radius: 8px;
}

@mixin default-img-box-style {
  @include img-box-style;
  background: $border;
  border: 2px dashed $usefy-red;
}

@mixin uploaded-img-box-style {
  @include img-box-style;
  background: $usefy-white;
  border: 2px solid $usefy-grey;
}

.new-eqipo-images {
  &__heading {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;
    color: $qip-blue-light;
    margin-bottom: 8px;
  }

  &__sub-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $ui-grey;
    margin-top: 10px;
    margin-bottom: 24px;
  }

  &__wallpaper {
    width: 100%;
    min-height: 115px;
  }

  &__img-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;

    > .upload-image {
      width: 100%;
      height: 97px;
    }
    >img {
      height: 97px;
    }
  }

  &__button {
    text-align: center;
  }
}

.coverNewEqipoImages {
  height: 187px;
  width: 100%;
  margin: auto;
  display: flex;

  align-items: center;
  justify-content: center;
}
