@import '../../../global/vars';

#review {
  height: 100vh;
  display: grid;
  grid:
    'menu' 133.22px
    'content' 1fr
    / 1fr;
}

.menu-review {
  grid-area: menu;
}

.boletoContainer, .pixContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  h4 {
    text-align: left;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    border-bottom: solid 1px $ui-grey-light;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 120%;
    text-transform: uppercase;
    color: $qip-blue-light;
  }

  .boletoDetail, .pixDetail {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 20px;
    
    .pixPng {
      background-color: $ui-background;
      padding: 20px;
      
      img {
        width: 200px;
        height: 200px;
        background-color: $ui-grey-light;
      }
    }

    .barecode, .pixQrCode {
      background-color: $ui-background;
      margin: 20px 0;
      padding: 10px 10px;
      color: $qip-blue-light;
      line-height: 120%;
      word-wrap: break-word;
      width: 340px;
    }

    .pixQrCode {
      overflow: scroll;
      white-space: nowrap;
    }

    .copyBarcode, .copyPixQrCode {
      width: 220px;
    }

    .linkToBoletoPDF, .linkToPixURL {
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
      text-transform: uppercase;
      color: $qip-blue;
      padding: 20px;
    }

    p {
      margin-top: 10px;
      margin-bottom: 30px;
      font-size: 14px;
    }

    .boletoInfo, .pixInfo {
      border-top: solid 1px $qip-soft-blue;
      border-bottom: solid 1px $qip-soft-blue;
      padding: 40px 20px;
      margin-bottom: 60px;

      align-items: center;
      display: flex;
      flex-direction: column;

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: $qip-blue;
      }

      .toMessageRoom {
        width: 200px;
      }
    }
  }
}
