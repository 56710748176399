@import '../../global/vars';

.eqipo-header {
  position: relative;

  &__title {
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(34, 34, 34, 0.8) 40%, rgba(34, 34, 34, 0) 100%);
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 15px;
    width: 100%;

    h1 {
      font-size: 2rem;
      font-weight: 600;
    }
  }
}

.page-eqipo {
  .hero {
    min-height: 333px;
    background-size: cover;

    &-body {
      position: relative;
    }

    .container {
      bottom: 20px;
      left: 20px;
      position: absolute;
      right: 20px;
    }

    h1.title {
      color: $white;
    }
  }

  h4.title {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    color: $usefy-black;
  }

  .eqipo-container {
    padding: 20px;
  }

  .seller-meta {
    .written-by {
      color: $usefy-grey;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
    }

    .seller-name {
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 30px;
      color: $usefy-black;
      margin-bottom: 5px;
      width: 100%;
    }

    .seller-from {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: $usefy-black;
    }

    .seller-avatar {
      height: 60px;
      width: 60px;
    }
  }

  .eqipo-history {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: $usefy-black;

    &::before,
    &::after {
      content: '"';
    }
  }

  .eqipo-description {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: $usefy-black;
  }

  .eqipo-specs {
    font-style: normal;
    font-size: 18px;
    line-height: 25px;
    color: $usefy-black;
  }

  .eqipo-price-blur {
    filter: blur(15px);
  }
  
  .eqipo-sold {
    margin: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .eqipo-price {
    color: $usefy-black;
    // display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;

    &.has-new-price {
      color: $usefy-grey;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      position: relative;

      &::after {
        content: '';
        display: block;
        border: 1px solid $usefy-grey;
        transform: rotate(-15.61deg);

        position: absolute;
        width: 110%;
        height: 0px;
        left: -5%;
        top: 48%;
      }
    }
  }

  .eqipo-condition-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $usefy-grey;
  }
  .eqipo-condition {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    color: $usefy-black;
  }

  .sport-tag {
    border: 1px solid $usefy-grey;
    margin: 2px;
    padding: 5px;
    border-radius: 3px;
    display: inline-block;
  }
}

.primaryButtonEqipoReview {
  margin: 12px 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 12px 9px 10px;
  min-width: 130px;
  height: 34px;
  background: $qip-coral;
  border-radius: 40px;

  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $ui-background-light;
}

.messageButtonEqipoReview {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 10px;

  height: 34px;
  background: $ui-background-light;
  border: 2px solid $qip-blue-light;
  box-sizing: border-box;
  border-radius: 5px;
  > span {
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #050D3D;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.containerEqipo {
  padding: 0 20px;
}

.coverEqipo {
  position: relative;
  height: 222px;
  margin-left: -20px;
  width: 100vw;

  // padding-top: 12px;

  div {
    position: absolute;
    left: -20px;
    height: 100%;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center;
  }

  strong {
    position: absolute;
    bottom: 10px;
    left: 30px;
    font-family: $family-primary;
    font-style: italic;
    font-weight: 900;
    font-size: 24px;
    line-height: 110%;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: $ui-background-light;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.titleEqipo {
  margin-top: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 100%;
  display: flex;
  align-items: flex-end;
  letter-spacing: -1px;
  text-transform: uppercase;
  color: $qip-blue-light;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profileEqipo {
  display: flex;

  img {
    height: 64px;
    width: 64px;
    border-radius: 100%;
  }

  div {
    margin-left: 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h5 {
    color: $qip-blue;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  strong {
    color: $qip-blue;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
  }

  small {
    color: $qip-blue;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    a {
      font-size: 12px;
      line-height: 18px;
      text-decoration-line: underline;
      color: $qip-coral-light;
    }
  }

  p {
    margin-top: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: $qip-blue;
  }
}

.storyEqipo {
  margin: 20px 10px;

  p {
    font-family: $family-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: $qip-blue;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 24px 0px;
    white-space: pre-wrap;
  }
}

.galleryContainerEqipo {
  width: calc(100% + 40px);
  margin-left: -20px;
}

.tagEqipo {
  margin-top: 32px;

  div {
    margin-top: 8px;
  }

  span {

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: $qip-blue-lighter;
    margin-right: 8px;
  }

}

.tagTitleEqipo {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  text-transform: uppercase;
  color: $qip-blue;
}

.specContainerEqipo {
  margin-top: 32px;

  hr {
    border: 1px solid $ui-grey-light;
    margin: 0;
  }
}

.specEqipo {
  display: grid;
  grid-template-columns: 113px 1fr;
  height: 40px;
  align-items: center;
  border-bottom: 1px solid #E0E0E0;

  strong {
    padding-right: 8px;
    width: 113px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    text-align: right;
    color: $qip-blue;
  }

  small {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: $qip-blue;

  }
}

.priceEqipo {
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  strong {
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $ui-blue-focus;
    font-weight: bold;
    font-size: 31px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: $qip-blue-light;
  }

  small {
    width: 100%;
    margin-top: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: $qip-blue-lighter;
    text-align: center;
  }


  .containerButton {
    width: 100%;
    margin-top: 32px;
  }
}

.adminZone {
  padding: 20px;
  background-color: #ffebcc;
  border: 2px dashed #ff9800; 
  text-align: center;
  border-radius: 5px; 
  margin: 20px; 
  position: relative;
  
  .adminZoneLabel {
    font-size: 18px;
    background-color: #ffebcc; 
    border: 2px dashed #ff9800;
    font-weight: bold;
    text-align: left;   
    margin-bottom: 10px;
    position: absolute; 
    top: -18px;
    padding: 6px;
    left: 50%; 
    transform: translateX(-50%); 
  }
}

