@import '../../global/vars';

.navbar {
  background-color: $ui-white !important;
  margin-bottom: 0;
  box-shadow: 0px 1px 0px #e0e0e0;
  height: 71px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__search {
    display: flex;
    margin-left: 14px;
  }

  &__search-form {
    flex-grow: 1;
    margin-right: 10px;
  }

  .navbar-burger {
    margin-right: 25px;
    background: none;
    border: none;
    color: $qip-blue-light;
    width: auto;
    margin-left: 8px;

    span {
      border-radius: 50px;
      height: 2.5px;
      width: 20px;

      &:nth-child(1) {
        top: calc(50% - 9px);
      }

      &:nth-child(2) {
        top: calc(50% - 2px);
      }

      &:nth-child(3) {
        top: calc(50% + 5px);
      }
    }

    &.is-active {
      color: $qip-blue-light;
      width: 12px;
      height: 12px;
      padding: 0;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 14px 0 0;

      span {
        border-radius: 50px;
        height: 2.5px;
        width: 18px;
        left: auto !important;

        &:nth-child(1) {
          top: calc(50% - 6px);
        }

        &:nth-child(2) {
          top: calc(50% - 2px);
        }

        &:nth-child(3) {
          top: calc(50% + 4px);
        }
      }
    }
  }
}

.navbar-brand {
  .menu__right {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .navbar-item {
      color: $qip-coral-light;
      font-weight: bold;
      font-size: 0.7rem;
      padding-right: 0;
      margin-right: 10px;
    }

    .navbar-signin {
      width: 18px;
      height: 18px;
    }
  }
}

.sidebar-menu {
  background-color: #fff;
  display: block;
  position: fixed;
  height: 100%;
  min-height: 100vh;
  top: 0;
  min-width: 100vw;
  max-width: 100vw;
  padding: 0;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  overflow: auto;

  .navbar-brand {
    background: $ui-background-light !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }

  ul {
    padding: 0;
  }

  .navbar-end {
    overflow-y: scroll;
  }
}

.sidebar-menu.is-active {
  transform: translateX(0);
}

.menu-overlay {
  display: none;

  &.is-active {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    z-index: 0;
  }
}

.menu {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
  height: 74px;

  .logo {
    width: 69px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background: transparent;
      color: #fff;
    }
    
    img {
      width: 71px;
    }
  }



  .menu-btn {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      color: $qip-blue-light;
    }
  }

  .menu-btn {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      color: $qip-blue-light;
    }
  }
}

.sport-list {
  margin-top: 0;
  margin-bottom: 44px;

  li {
    display: flex;
    flex-direction: column;

    ul > li {
      margin: 10px 0;
    }

    .navbar-item,
    .navbar-item-without-parents,
    .navbar-subitem {
      color: $qip-blue-light;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.3rem;

      img {
        margin-right: 6px;
      }
    }

    .navbar-item {
      font-size: 20px;
      font-style: italic;
      font-weight: 900;
      justify-content: space-between;
      line-height: 35px;
      padding-left: 0.8rem;
      width: 100%;
      text-transform: uppercase;
      background: $ui-background-light;
    }

    .navbar-item-without-parents,
    .navbar-subitem {
      font-size: 20px;
      font-style: italic;
      font-weight: 900;
      justify-content: flex-start;
      margin-left: 0.8rem;
      margin-right: 1.6rem;
    }

    .navbar-subitem {
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
    }
  }
}
