@import '../../global/vars';

.shipping-address {
  font-size: 0.9rem;
  margin: 10px 0;
}

.shipment {
  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $ui-grey;
  }
  
  &__title {
    color: $usefy-black;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
}

.address-fields {
  display: grid;
  grid-template-columns: 47% 47%;
  grid-column-gap: 6%;
}

