@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,400;0,800;1,200;1,400;1,800&display=swap');

@import 'vars';

// Bulma modules
@import '../../node_modules/bulma/sass/utilities/all';
@import '../../node_modules/bulma/sass/base/all';
@import '../../node_modules/bulma/sass/elements/all';
@import '../../node_modules/bulma/sass/form/all';
@import '../../node_modules/bulma/sass/components/all';
@import '../../node_modules/bulma/sass/grid/all';
@import '../../node_modules/bulma/sass/layout/all';
@import '../../node_modules/bulma/sass/helpers/all';

// Bulma extensions
@import '../../node_modules/bulma-checkradio/src/sass/index';

// gambis
.Toastify__toast--error {
  border: 3px solid #FF6E5C;
  border-radius: 5px !important;
  background: #FAB4B4 !important;
  margin-bottom: 10px !important;
}

.Toastify__toast--error::before {
  content: url("../../public/images/errorMessage.png");
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 13px;
}

.Toastify__toast--success {
  border: 3px solid #5ADC56 !important;
  border-radius: 5px !important;
  background: #C8FDAB !important;
  margin-bottom: 10px !important;
}

.Toastify__toast--warning {
  border: 3px solid #F5D215 !important;
  border-radius: 5px !important;
  background: #FAEEB4 !important;
  margin-bottom: 10px !important;
}

.Toastify__toast-container {
  padding: 10px !important;
}

.Toastify__toast-body {
  color: #050830;
  font-size: 12px;
  padding: 10px !important;
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 5px !important;
}

.Toastify__toast > button > svg {
  display: none;
}


* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: 'Source Sans Pro';
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  body {
    line-height: 1;
    width: 100vw;
    height: 100svh;
  }
}

ol,
ul {
  list-style: none;
}

button {
  outline: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button.is-outlined {
  border-width: 2px;
}

input.input {
  border: 1px solid $ui-background !important;
  box-shadow: inset 0 0 2.5px rgba(41, 41, 41, 0.3);

  &:focus {
    outline: none !important;
    border: 1px solid #0067c5 !important;
    box-shadow: 2px -2px 0px #afc4f9, -2px 2px 0px #afc4f9, 2px 2px 0px #afc4f9,
    -2px -2px 0px #afc4f9;
  }
}

section.home {
  min-height: 50vh;
}

.button.is-primary {
  background-color: $qip-coral-light;

  &:hover,
  &.is-hovered,
  &:focus,
  &.is-focused,
  &:active,
  &.is-active {
    background-color: $qip-coral-light;
  }
}

// React select
div[class*="-menu"] {
  z-index: 9999 !important;
}