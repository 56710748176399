@import '../../../../global/vars';

.modalFinishNewEqipo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 15px;
  position: relative;
  width: 335px;
  // height: 285px;

  background: $ui-white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);


  strong {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: $qip-blue;
    margin-bottom: 16px;
  }


  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: $qip-blue;

  }

  small {
    margin-top: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: $qip-coral-light;
  }


}


.modalFinishContainerNewEqipo {
  position:absolute;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background: rgba(0,0,0,0.5);
  display:flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

}

.buttonsContainerNewEqipoReview {
  margin-top: 16px;
  width: 100%;
}
