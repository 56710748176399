@import '../../node_modules/bulma/sass/utilities/initial-variables';

// COLORS
$qip-blue: #050830;
$qip-blue-light: #050d3d;
$qip-blue-lighter: #7D829B;
$qip-coral: #e05e4d;
$qip-coral-light: #ff6e5c;
$qip-green: #34c924;
$qip-green-light: #85fa45;
$qip-soft-blue: #80a1f5;

$ui-blue-system: #0067c5;
$ui-red-system: #f55915;
$ui-blue-link: #0d359c;
$ui-red-error: #fab4b4;
$ui-error-shadow: #ff6e5c;
$ui-yellow-light: #faeeb4;
$ui-yellow: #f5d215;
$ui-blue-focus: #afc4f9;
$ui-black: #292929;
$ui-green-light: #C8FDAB;
$ui-grey-dark: #424242;
$ui-grey: #616161;
$ui-grey-medium: #bdbdbd;
$ui-grey-light: #e0e0e0;
$ui-background: #f5f5f5;
$ui-background-light: #fafafa;
$ui-white: #ffffff;

$usefy-black: $ui-black;
$usefy-grey-darker: $ui-grey-dark;
$usefy-grey-dark: $ui-grey;
$usefy-grey: $ui-grey-light;
$usefy-grey-light: $ui-background-light;
$usefy-grey-lighter: $ui-background;
$usefy-white: $ui-white;
$usefy-red: $ui-red-system;
$usefy-orange: $ui-red-system;
$usefy-yellow: $ui-yellow;
$usefy-green-dark: $qip-green;
$usefy-green: $qip-green;
$usefy-green-light: $qip-green-light;
$usefy-blue-dark: $qip-blue;
$usefy-blue: $qip-soft-blue;
$usefy-blue-light: $qip-soft-blue;
$usefy-purple-dark: #9b51e0;
$usefy-purple-light: #bb6bd9;

// Initial variables
$black: $ui-black;
$black-bis: $ui-black;
$black-ter: $ui-black;
$grey-darker: $ui-grey-dark;
$grey-dark: $ui-grey;
$grey: $ui-grey-light;
$grey-light: $ui-background;
$grey-lighter: $ui-background-light;
$white-ter: $ui-white;
$white-bis: $ui-white;
$white: $ui-white;
$orange: $ui-red-system;
$orange-invert: $ui-red-system;
$yellow: $ui-yellow;
$yellow-invert: $ui-yellow;
$green: $qip-green;
$green-invert: $qip-green-light;
$turquoise: $qip-green-light;
$turquoise-invert: $ui-white;
$cyan: $qip-blue;
$cyan-invert: $ui-white;
$blue: $qip-blue;
$blue-invert: $ui-white;
$purple: #9b51e0;
$purple-invert: $ui-white;
$red: $qip-coral;
$red-invert: $ui-white;

// Derived variables
$primary: $qip-coral;
$primary-invert: $white;
$info: $cyan;
$info-invert: $white;
$success: $green;
$success-invert: $white;
$warning: $yellow;
$warning-invert: $black;
$danger: $red;
$danger-invert: $white;
$dark: $blue;
$light-invert: $dark;
$light: $white;
$dark-invert: $light;
$background: $white;
$border: $grey-light;
$border-hover: $grey-dark;
$text: $grey-dark;
$text-invert: $white;
$text-light: $grey;
$text-strong: $grey-darker;
$link: $cyan;
$link-invert: $white;
$link-visited: $cyan;
$link-hover: $cyan;
$link-hover-border: $cyan;
$link-focus: $cyan;
$link-focus-border: $cyan;
$link-active: $cyan;
$link-active-border: $cyan;

// FONTS
$family-primary: 'Source Sans Pro', sans-serif;
$family-secondary: $family-primary;

// Other
$bulmaswatch-import-font: true !default;
