@import '../../global/vars';

.uploadImage {
  .deleteIcon {
    position: absolute;
    display: inline-block;
    width: 26px;
    height: 26px;
    right: 4px;
    bottom: 4px;
  }
}

