@import '../../global/vars';

.eqipos {
  padding: 12px 20px;
  margin-bottom: 50px;
  
  &__list {
    display: grid;
    grid-template-columns: 100%;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 21px;
  }

  &__search-title {
    font-weight: bold;
    font-size: 16px;
    color: $usefy-blue-dark;
    display: flex;
    align-items: center;

    > img {
      width: 8px;
      margin-right: 12px;
      transform: rotateY(180deg);
    }
  }
  
  .eqipos-header {
    font-weight: bold;
    font-size: 16px;
    color: $usefy-blue-dark;
    display: flex;
    margin-bottom: 12px;
    flex-direction: column;
    align-items: flex-start;
    
    .eqipos-header-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    
    .filter-list {
      display: grid;
      grid-template-columns: 67px calc(100% - 67px);
      margin: 10px 0;

      > div {
        display: flex;
        flex-wrap: wrap;
      }

      > strong {
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__title {
      flex-grow: 1;
    }

    &__filter-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #BDBDBD;
      width: 77px;
      height: 24px;
      padding: 5px 6px;
      font-size: 11px;
      font-style: normal;
      line-height: 140%;
      text-transform: uppercase;
      color: $qip-blue;
      font-weight: normal;

      > img {
        width: 12px;
        height: 14px;
      }
    }
    
    &__filter-count {
      display: none;
      width: 23px;
      height: 23px;
      background: $usefy-red;
      color: $usefy-white;
      border-radius: 50%;
      margin-right: 3px;

      &--active {
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
    
    &__filter-list {
      display: grid;
      grid-template-columns: 67px calc(100% - 67px);
      margin: 10px 20px;

      > div {
        display: flex;
        flex-wrap: wrap;
      }

      > strong {
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
   }
  }
}


.grid-system {
  width: 48px;
  height: 24px;
  display: grid;
  grid-template-columns: 24px 24px;
  border: 1px solid #BDBDBD;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    height: 14px;
    width: 14px;
  }

  &__selected {
    border: 1px solid #616161;
  }
}
