@import '../../global/vars';

.notification-element {
  border-bottom: 1px solid $grey;
}

.notification-element a {
  display: block;
  padding: 5px 10px;
}

.notification-element a.read {
  background-color: $grey-lighter;
  color: $grey;
}

.notification-subject {
  font-weight: bold;
}
