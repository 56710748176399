@import '../../global/vars';

#profile header {
  display: flex;
  margin: 1rem;
  flex-direction: column;
}

#profile header .main {
  display: flex;
}

#profile h6 {
  margin-top: 15px;
  padding-bottom: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  text-transform: uppercase;
  color: $qip-blue-light;
  border-bottom: 1px solid $ui-grey-light;
}

#profile header .main .avatar {
  border-radius: 50%;
  border: none;
  display: flex;
  height: 110px;
  max-width: 110px;
  min-width: 110px;
}

#profile header .main .avatar img {
  display: flex;
  border-radius: 50%;
}

#profile header .main div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#profile header .main div:nth-child(2) {
  margin-left: 20px;
}

#profile header .main div strong {
  color: $qip-blue;
  font-style: italic;
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
}

#profile header .main div strong:nth-child(3) {
  color: $qip-blue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: none;
  margin-top: 6px;
}

#profile header .main div span {
  color: $qip-blue;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  margin-top: 6px;
}

#profile header .description {
  display: flex;
  flex-direction: column;
}

#profile header .description .display-name {
  display: flex;
  align-items: center;
  margin-left: 20px;

  strong {
    color: $qip-blue;
    font-style: italic;
    font-weight: 900;
    font-size: 18px;
    text-transform: uppercase;
  }
}

#profile header .main .display-btns {
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-gap: 10px;
  height: 26px;
}

#profile header .description .display-name .icon-share {
  margin-right: 0.8rem;
  background: $usefy-white;
  border: 0;
  color: #2d9cdb;
}

#profile header .description .display-name .icon-share:hover {
  outline: none;
}

#profile header .description .display-name .icon-edit {
  text-decoration: none;
  color: #000;
}

#profile header .description span {
  color: #000;
  margin-top: 0.8rem;
}

#profile header .description img {
  width: 5rem;
  height: auto;
  margin-top: 1rem;
}

#profile header .description {
  .my-sports {
    p {
      color: #000;
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 140%;
      text-transform: uppercase;
    }

    .my-sports-list {
      display: flex;

      .my-sports-card {
        height: 30px;
        display: flex;
        box-shadow: 0px 1px 0px rgba(5, 13, 61, 0.25);
        border-radius: 25px;
        padding: 6px 8px;
        max-width: 90px;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        margin-top: 6px;

        img {
          margin: 0;
          height: auto;
          width: 30px;
          margin-right: 3px;
        }

        span {
          color: $qip-blue;
          margin: 0;
          font-style: italic;
          font-weight: 900;
          font-size: 9px;
          text-transform: uppercase;
        }
      }
    }
  }
}

#profile .main {
  margin: 20px 0;

  .title-banner {
    width: 100%;
    height: 53px;
    background: $qip-blue;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: $ui-white;
      font-weight: bold;
      font-size: 22px;
      font-style: italic;
    }
  }

  .menu-control {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 26px;
    margin-bottom: 15px;
    padding: 0 20px;

    .grid-system {
      display: flex;

      span {
        width: 24px;
        height: 24px;
        border: 1px solid $ui-grey;
        padding: 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          color: $qip-blue;
        }
      }
    }
  }
}

.qip-adventure {
  display: flex;
  justify-content: center;
  padding: 50px 0;
  img {
    width: 140px;
  }
}
