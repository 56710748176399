@import '../../global/vars';

.checkoutConfirm {
  padding-top: 16px;

  .checkoutConfirmTitle {
    padding: 10px 0;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: $qip-blue;
  }
  
  .checkoutConfirmShipment {
    padding-bottom: 10px;
  }
  
  .checkoutConfirmShipmentDetail {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $ui-grey;
  }
}

.credit-card-preview {
  background-color: $usefy-grey-light;
  color: $usefy-black;
  font-weight: bold;
  padding: 30px 20px;
  max-width: 60%;
  border-radius: 10px;
  border: 2px solid $usefy-grey;
  margin: 10px auto;

  &__holder {
    text-transform: uppercase;
  }
}

.cost {
  display: grid;
  grid-template-columns: 50% 50%;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 240%;
  color: $qip-blue;
  border-bottom: 1px solid $ui-grey-light;

  > p {
    margin: 0;
    padding: 0 5px;
  }

  &__installments {
    font-weight: normal;
  }

  &__installments:first-child {
    padding-left: 10px;
  }

  p:last-child {
    text-align: right;
  }

  &--summary {
    background-color: $ui-background;
  }
}


.brCheckoutConfirm {
  height: 20px;
}

.emptyCost {
  height: 32px;
}
