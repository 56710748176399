@import "../../global/vars";

.confirm-dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 99;

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
  }

  .dialog-modal {
    position: relative;
    margin: 22px;
    padding: 30px 20px;
    background-color: $usefy-white;
    text-align: center;
    opacity: 1;
    z-index: 9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    &__close-button {
      position: absolute;
      top: 24px;
      right: 24px;
    }

    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 120%;
      text-align: center;
      text-transform: uppercase;
      color: $qip-blue;
    }
    &__description {
      padding: 16px 0;

      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: $qip-blue;
    }
    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      >button {
        width: auto;
        padding: 10px;
      }
    }
  }
}
