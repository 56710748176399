@import '../../global/vars';

.badge {
  background-color: $qip-coral;
  color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 0px;
  font-size: 12px;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}