@import '../../global/vars';

.bankAccount-edit .bankAccount-form-edit {
}

.bankAccount-edit .bankAccount-kyc {
  padding: 80px 10px;
  margin: 10px 20px;
  
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: $qip-blue-light;
  }
  
  p {
    padding: 16px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: $qip-blue-light;
  }
  
  span {
    color: $qip-coral;
  }
}

.bankAccount-edit .bankAccount-form-edit .btnUpdate {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: $qip-coral;
  border-radius: 40px;

  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: $ui-background-light;

  &:hover {
    outline: none;
  }
}


.gridCol2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.headerBankAccount {
  > h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;
    color: $qip-blue;
    margin-bottom: 8px;

  }
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: $qip-blue;
  }
}
