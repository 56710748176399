@import '../../global/vars';

.field {
  position: relative;
  padding-top: 16px;
  margin: 0 !important;
  label.label {
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: $qip-blue;

    .required {
      font-size: 0.8rem;
      color: $usefy-grey;
      font-weight: 300;
      margin-left: 6px;
    }
    .optional {
      position: absolute;
      right: 0;
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      line-height: 140%;
      display: flex;
      align-items: flex-end;
      text-align: right;
      color: $ui-grey-medium;
    }
  }

  .error-message {
    font-size: 1rem;
    color: $usefy-red;
    margin-top: 10px;
  }

  .validation-error {
    input.input, textarea.textarea, div.react-select-container > div {
      border: 1px solid $usefy-red;
    }
  }

  input.input, textarea.textarea, div.react-select-container > div {
    height: 40px;
    border-radius: 0;
    padding: 8px 11px;
    border: 1px solid $ui-grey-light;
    box-sizing: border-box;
  }

  .input-prefix-container {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;

    .input-prefix {
      height: 53%;
      left: 12px;
      position: absolute;
      padding: 0 3px 0 5px;
      margin-right: 5px;
      display: flex;
      align-items: center;
      z-index: 1;
      background-color: #E6E5E5;
      color: #333333;
      pointer-events: none;
      border-radius: 2px;
      font-size: 85%;
    }

    input.input-prefixed {
      z-index: 0;
    }
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 21px;
    font-style: normal;
    font-weight: normal;



    &__label {
      margin: 0 10px;
    }

    &__span_left {
      margin-right: 5px;
      font-size: 14px;
      line-height: 140%;
      color: #000000;
    }

    &__span_right {
      margin-left: 5px;
      font-size: 14px;
      line-height: 140%;
      color: #000000;
    }
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $ui-background;
    // box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid $ui-grey-light;
    border-radius: 1px;
    -webkit-transition: .4s;
    transition: .4s;
    padding: 1px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    top: 0;
    left: 0;
    background: #FFFFFF;
    box-shadow: 1px 0px 0px rgba(125, 130, 155, 0.6), inset 0px -1px 0px #BDBDBD;
    border-radius: 1px;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: $ui-blue-focus;
  }



  input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }
}

/* The radio container */
.radio {
  height: 24px;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: $qip-blue;
  padding-left: 22px;
  width: 100%;

  input[type=radio]  {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  span {
    position: absolute;
    top: 2.5px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 1px;
  }

  &:hover input ~ span {
    background-color: #ccc;
  }

  input:checked ~ span {
    background-color: $qip-coral-light;
    border: 2px solid $qip-coral-light;
  }

  span:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ span:after {
    display: block;
  }

  // The radio circle
  .after:after {
    top: 3.5px;
    left: 3.5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: $ui-background;
    box-shadow: 0px 1px 1px rgba(5, 8, 48, 0.5);
  }
}

/* The checkbox container */
.checkbox {
  display: block;
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  padding-left: 15px;
  text-indent: 8px;
  color: $qip-blue;
  cursor: pointer;
  width: 100%;
  a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    text-indent: 8px;
    text-decoration-line: underline;
    color: $ui-blue-link;

  }

  input[type=checkbox]  {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: $ui-background;
    border: 1px solid $ui-grey-light;
    box-sizing: border-box;
    border-radius: 1px;
    margin-top: 6px;
  }

  &:hover input ~ span {
    background-color: #ccc;
  }

  input:checked ~ span {
    background-color: #fff;
    border-radius: 4px;

    // border: 2px solid $usefy-grey;
  }

  span:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ span:after {
    display: block;
  }

  // The checkbox square
  span:after {
    top: 0px;
    left: 0px;
    width: 16px;
    height: 16px;
    border-radius: 1px;
    background: $qip-coral-light;
  }
}

.textarea {
  &__caption {
    font-size: 0.8em;
    padding: 1em 0;
    display: inline-block;
  }
}

.check-box-content {
  padding: 1px 0 0 8px;
  
  > strong {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.8;
    margin-left: -8px;
    text-transform: uppercase;
    color: $qip-blue-light;
  }
  
  > a {
    font-style: normal;
    text-transform: none;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.2;
    text-decoration-line: underline;
    color: $ui-blue-link;
  }
}
