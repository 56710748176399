@import '../../global/vars';


.desktop {
  background-position: top center;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  
  header {
    width: 100%;
    height: 70px;
    background-color: $qip-blue;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 70px;
    }
  }

  .container {
    padding: 0;
    text-align: center;

    h1 {
      margin-top: 75px;
      font-size: 40px;
      color: $qip-blue;
      font-weight: bold;
      font-style: italic;
    }

    h2 {
      margin-top: 75px;
      font-size: 40px;
      color: $qip-coral;
      font-weight: bold;
      font-style: italic;
    }

    .qrcode {
      margin-top: 20px;
      img {
        width: 200px;
      }
    }

    p {
      font-size: 24px;
      color: $qip-blue;
      font-style: italic;

      span {
        color: $qip-coral;
      }
    }
  } 
}