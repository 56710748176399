@import '../../../global/vars';

#review {
  height: 100vh;
  display: grid;
  grid:
    'menu' 133.22px
    'content' 1fr
    / 1fr;
}

.menu-review {
  grid-area: menu;
}

.content-review {
  grid-area: content;
  background: #c4c4c4;
  padding: 25px;
}

.content-review .content-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 100%;
  padding: 15px;
  text-align: center;

  .description-1 {
    color: #050d3d;
    margin-bottom: 1.2rem;
  }

  .description-2 {
    color: #050d3d;
  }

  .description-3 {
    color: #050d3d;
    margin-bottom: 2rem;
  }

  .description-4 {
    color: #bdbdbd;
    margin-bottom: 3rem;
  }

  .button-details {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3.7rem;
    background: #ff5349;
    color: #fff;
    border: 0;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
  }

  .button-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3.7rem;
    background: #fff;
    color: #ff5349;
    border: 3px solid #e05e4d;
    box-sizing: border-box;
    border-radius: 0.5rem;
  }
}
