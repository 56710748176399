@import '../../../global/vars';

#messages .main {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 8px;
  height: 104px;
  margin: 24px 0 0 0;
  padding: 8px 20px 16px;
  border-bottom: 1px solid $ui-grey-light;
}

.product-image {
  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
}


#messages .main .details {
  p {
    text-transform: uppercase;
    font-size: 14px;
    color: $qip-blue;
    margin-bottom: 18px;
  }

}

#messages .list-messages {
  overflow-y: scroll;
  height: calc(100svh - 330px);
  max-height: 50%;

  ul {
    display: flex;
    flex-direction: column;
    margin: 1.2rem;

    .message-left {
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      > div {
        margin-left: 8px;
      }
    }
  }

  .date-messages {
    margin: 0 20px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $ui-grey-light;

    div {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      color: $qip-blue-light;
    }
  }


}

#messages .send-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  justify-content: center;
  background: $ui-background;

  input {
    width: 70vw;
  }

  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 0;
}

#messages .send-message .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.8rem;
  border: 0;
  outline: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #FF5349;

  img {
    width: 15px;
  }
}

#messages .list-messages .message-right{
  width: auto;
  margin: 8px 0px;
  display: flex;
  justify-content: flex-end;
  div {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    padding: 5px 8px;
    background: $ui-blue-focus;

    >p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: $qip-blue;
    }

    span {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 140%;
        text-align: right;
        color: $ui-grey;
        > img {
          width: 12px;
          height: 6px;
        }
    }
  }
}

#messages .list-messages .message-transactional{
  margin: 10px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: $ui-grey-light 1px solid;

  div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: $qip-coral;
    display: flex;
    flex-direction: row;
  }
}


#messages .list-messages .message-left{
  width: auto;
  margin: 8px 0px;
  display: flex;
  justify-content: flex-start;
  > img {
    height: 30px;
    width: 30px;
  }
  div {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    padding: 5px 8px;
    background: $ui-grey-light;

    >p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: $qip-blue;
    }

    span {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 140%;
        text-align: right;
        color: $ui-grey;
    }
  }
}

.edit-price-button {
  width: 40px;
  > button {
    width: 100%;
  }
}

.edit-price {
  #price {
    font-size: 15px;
    padding: 20px 10px;
  }
  
  > button {
    margin-left: 10px;
    padding: 0 10px;
    width: auto;
  }
  
  > button.secondaryNoBorder {
    padding: 0;
  }
}

.alreadySoldMessage {
  width: 100%;
  background: rgba(245, 245, 245, 0.5);
  display: flex;
  align-items: flex-end;
  margin-bottom: -20px;
  > div {
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px 30px;
    background: $ui-yellow-light;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.15);
    > span {
      color: $qip-blue;
      line-height: 130%;
      font-size: 15px;
      text-transform: uppercase;
    }
    > a {
      line-height: 130%;
      font-size: 15px;
      text-decoration-line: underline;
      color: $qip-coral;
    }
  }
}

.buyInfoMessages {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 30px;
  height: 48px;
  width: 100%;
  background: $ui-background-light;
  > span {
    font-style: normal;
    font-size: 18px;
    line-height: 130%;
    font-weight: normal;
    color: $qip-blue;
    > b {
      font-weight: bold;
    }
  }
  > button {
    background: #050D3D;
    border-radius: 5px;
    padding: 9px 12px 9px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 66px;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $ui-background-light;
  }
}


.moreMessagesContainer {
width: 100%;
display: flex;
justify-content: center;
align-items: center;

}
.moreMessagesButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $ui-background-light;
  border-radius: 15px;
  border: 1px solid $ui-grey-medium;
  color: $qip-blue;
  font-size: 14px;
  padding: 5px 10px;
  >svg {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
}

.imgCheckMesssage {
  margin-top: 2px;
  margin-left: 2px;
}

.order-status-banner {
  left: 0;
  top: 0;
  padding: 5px 20px;
  text-align: center;
  background: $ui-yellow-light;
  color: $qip-blue;
  font-size: 14px;
  width: 100%;
}
