@import "../../../global/vars";

#profile {
  > .profile-menu {
    margin-top: 36px;
    background: #fff;
    border: none;
    border-radius: 5px;
    color: #444;
    font-size: 0.9rem;
    min-width: 50vw;
    right: 0;

    &:after {
      content: "";
      width: 10px;
      height: 10px;
      transform: rotate(-45deg);
      background: #fff;
      position: absolute;
      z-index: -1;
      top: -5px;
      right: 10px;
    }

    > .profile-menu__items {
      > .profile-menu__exit {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        > p {
          color: $qip-coral;
        }
      }

      > .profile-menu__item {
        border-bottom: 1px solid $ui-grey-light;
        height: 40px;

        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background: $ui-background;
        }

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          color: $qip-blue-light;
        }

        img {
          height: 8px;
          width: 5px;
        }
      }
    }
  }
}
