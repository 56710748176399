@import '../../../global/vars';

.filter-header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-bottom: 16px;
    height: 40px;
    width: 100%;

    li {
      text-align: center;
    }
    
    .filter-seller {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $ui-background-light;
      border: solid 1px $ui-grey-medium;
      border-bottom: solid 1px $ui-grey-light;
      border-top: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      text-transform: uppercase;
      color: #7d829b;

      &.active {
        border: solid 1px $ui-grey-light;
        border-top: 0;
        background: $ui-white;
        border-bottom: none;
        font-weight: bold;
        color: $qip-blue;
      }
    }
  }

  .filter-body {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    height: 28px;
    margin: 0 20px;
  }
}

.selectContainer {
  width: 154px;
  margin-right: 20px;
}
