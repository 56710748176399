@import '../../global/vars';

.paymentMethod {
  .control {
    padding: 5px;
  }
}

.credit-card {
  .has-icons-right > svg {
    position: absolute;
    right: 0;
    top: 0;
    height: 20px;
    padding: 11px;
    color: $qip-blue-light;
  }

  .credit-card-details {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    
    .field {
      flex: 1;
    }
    
  }
}

.subtitleCheckoutPayment {
  margin-top: 16px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $ui-grey-light;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  text-transform: uppercase;
  color: $qip-blue-light;

}

.labelText {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $ui-grey;
  margin-left: 20px;
}
