@import '../../global/vars';

.about-us-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  color: $qip-blue-light;

  h1, h2 {
    font-style: italic;
    font-weight: 900;
    text-align: center;
    margin: 10px 0 30px 0;
    font-size: 31px;
    line-height: 100%;
    letter-spacing: -1px;
    color: $qip-blue-light;
    text-transform: uppercase;
    padding: 0 20px;
  }

  h2 {
    margin: 40px 0 40px 0;
    font-size: 25px;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    position: relative;
  }

  .video-wrapper {
    position: relative;
    width: 100%;
    background: black;
    margin-bottom: 35px;
  }

  .video-aspect-ratio {
    position: relative;
    width: 100%;
    padding-top: 125.13%; /* Aspect ratio 390/488 */
  }


  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .imageWrapper {
    position: relative;
    right: 0;
    top: 0;
    width: 150px;
    float: right;
  }
}
