@import "../../global/vars";

.checkout-eqipo {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-top: 32px;
  border-bottom: 1px solid $ui-grey-light;

  &__content {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__image {
    flex: 0.5;
    display: flex;
    justify-content: flex-end;
    img {
      width: 100%;
      max-height: 110px;
      object-fit: cover;
    }
  }

  &__title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    color: $qip-blue-light;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__subtitle {
    margin-bottom: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: $ui-grey;

  }

  &__description {
    flex: 0.7;
    padding-right: 10px;
  }

  &__prices {
    display: flex;
    flex-direction: column;

    > h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 120%;
      text-transform: uppercase;
      color: $qip-blue-light;
    }

    > small {
      padding-top: 4px;
      margin-left: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #7D829B;

    }

  }
}
