@import '../../global/vars';

.emptyEqipoMySales {
  display: flex;
  flex-direction: column;
  
  > div {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  padding: 0 10px;
  }
  >strong {
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
  }
  >hr {
    border: 1px solid $qip-blue-light;
  }
}

.headerMySales {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  border: none;
  padding: 10px 20px 10px;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
  > svg {
    width: 16px;
    height: 16px;
  }
  > span {
    margin-left: 10px;
    font-style: italic;
    font-weight: 900;
    font-size: 24px;
    line-height: 110%;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: $qip-blue;
  }
}

.moneyMySales {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border: none;
  > svg {
    height: 10px;
  }
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 200%;
    color:  $qip-blue;
  }
}

.containerMySales {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px
}

.messageEqipoItem {
  margin-top: 5px;
  width: 108px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $ui-background-light;
  border: 2px solid $qip-blue-light;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  line-height: 110%;
  text-transform: uppercase;
  color: $qip-blue-light;
}

.buttonEqipoItem {
  margin-top: 5px;
  width: 134px;
  height: 29px;
  background: $qip-coral;
  border-radius: 40px;
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  line-height: 110%;
  text-align: center;
  text-transform: uppercase;
  color: $ui-background-light;
 
}
.buttonEqipoItem:disabled {
  background: $ui-grey-medium;
}

.containerButtonEqipoItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}