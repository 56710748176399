@import '../../global/vars';

.notFoundPageContainer {
  padding: 30px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  h1 {
    color: $qip-coral-light;
    font-size: 60px;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    margin: 10px 0 20px 0;
    line-height: 100%;
    letter-spacing: -1px;
    text-transform: uppercase;
  }
  
  p {
    strong {
      color: $qip-blue-light;
      line-height: 200%;
    }
    
    color: $qip-blue-light;
  }
}