@import '../../global/vars';

.containerFormPagination {
  height: 56px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.stepFormPagination {
  position: relative;
  background: $ui-white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 11px;
}

.circleFormPagination {
  min-width: 30px;
  min-height: 30px;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background: $ui-grey-light;
  display: flex;
  justify-content: center;
  align-items: center;

  color: $qip-blue;
  font-size: 16px;
  font-weight: bold;
}

.selectedFormPagination {
  background: $qip-blue-light;
  color: $ui-background-light;
  border-color: $qip-blue-light;
}

.textStepeFormPagination {
  font-family: $family-primary;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  text-transform: uppercase;
  margin-top: 8px;
}

.hrFormPagination {
  position: absolute;
  top: 40%;
  height: 1px;
  width: calc(100% - 60px);
  left: 30px;
  background: $ui-grey-light;
}
