@import '../../../global/vars';

.Rooms {
  margin-top: 24px;

  .tile {
    padding: 5px 0.75rem;
    margin-bottom: 18px;

    &:hover {
      background: $ui-yellow-light;
    }
  }
}

#message .main {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $usefy-black;
}

#message .main .product-image {
  min-width: 64px;
  width: 64px;
  height: 60px;
  position: relative;

  img {
    width: 64px;
    height: 60px;
    border-radius: 5px;
  }

  .profile-mini-image {
    border-radius: 50%;
    border: solid 1px $ui-white;
    background: $ui-white;
    right: -5px;
    width: 31px;
    height: 31px;
    position: absolute;
    top: 0;
    z-index: 9;

    img {
      width: 31px;
      height: 31px;
      border-radius: 50%;
    }
  }
}

#message .main .details {
  margin-left: 0.8rem;
  width: 75%;

  p {
    font-size: 14px;
    text-transform: uppercase;
    color: $qip-blue;
    margin-top: 8px;
  }

  .chat-tag {
    width: 58px;
    padding: 1px 6px 1px;
    border: 1px solid $ui-grey-light;
    color: $ui-grey-medium;
    font-size: 11px;
    border-radius: 1px;

    &.tag-selling {
      color: $qip-blue;
      background: $ui-yellow-light;
      border-color: transparent;
    }

    &.tag-sold {
      color: $qip-blue;
      background: $ui-green-light;
      border-color: transparent;
    }

    &.tag-shopping {
      color: $qip-blue;
      background: $ui-blue-focus;
      border-color: transparent;
    }

    &.tag-bought {
      color: $qip-blue;
      border-color: $ui-blue-focus;
    }
  }
}

#message .main .details .seller {
  display: flex;

  img {
    display: flex;
    width: 30px;
    height: auto;
    border-radius: 50%;
  }
}

#message .main .details .seller .info {
  display: flex;
  flex-direction: column;

  span {
    margin: 8px 0 4px;
    font-size: 14px;
  }

  label {
    font-size: 11px;
  }

  .new-info {
    color: $usefy-red;
  }

  .old-info {
    color: $ui-grey;
  }
}

#message .main .arrow {
  img {
    margin-left: 15px;
    margin-right: 6px;
    width: 10px;
    height: auto;
  }
}
