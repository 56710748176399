@import '../../global/vars';

.containerImage {
  position: relative;
  width: calc(100vw - 10px);
  .BrainhubCarousel__dots {
    .BrainhubCarousel__thumbnail--selected {
      opacity: 0.4 !important;
    }
  }
}
.eqipoGallery {
  position: relative;
  .BrainhubCarousel__dots {
    margin-top: 7px;
    .BrainhubCarousel__thumbnail {
      padding: 0;
      margin: 0 5px;
      opacity: 1;
    }
    .BrainhubCarousel__thumbnail--selected {
      border: 1px solid $qip-blue;
      opacity: 0.4 !important;
    }
  }
}

.imageContainer {
  position: relative;
  text-align: center;
  width: 100%;
  height: 251px;
}

.imageBlur {
  background-position: center center;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 251px;
  filter: blur(5px);
  opacity: 0.95;
}

.fullscreenContainer {
  position: fixed;
  z-index: 40;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imageFullscreen {
  height: 251px;
}

.closedButton {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 35px;
  height: 35px;
}

.photoFullscreenContainer {
  display: flex;
  margin-top: 20px;
  .photoFullscreen {
    width: 45px;
    height: 5px;
    border-radius: 30px;
    background: $ui-grey-light;
    margin: 0 2.5px;
  }
  .photoFullscreen_selected {
    background: $qip-coral-light;
  }
}

.fullscreenSwiper {
  width: 100%;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: transparent; 
  border: none; 
}

.swiper-button-prev {
  transform: rotate(180deg); 
}

.swiper-button-next:after,
.swiper-button-prev:after {
  display: none;
}

:root {
  --swiper-pagination-color: #ff6e5c; 
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 1;}
