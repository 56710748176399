iframe[name="Messaging window"] {
  z-index: 2 !important;
  position: absolute !important;
  width: 100% !important;
  height: 80vh !important;
  top: 74px !important;
  left: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
