@import '../../global/vars';

.container {
  padding: 0 20px;

  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 31px;
    line-height: 37px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: $qip-blue-light;

  }
}
