@import '../../global/vars';

.container {
  padding: 20px;
}

.container.home {
  padding: 10%;
  font-size: 1.2em;
  margin-bottom: 20px;

  p {
    line-height: 1.5em;
    margin: 20px 0;
  }
}
