@import '../../global/vars';

.emptyMyBalance {
  display: flex;
  flex-direction: column;

  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    padding: 0 10px;
  }

  > strong {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
  }

  > hr {
    border: 1px solid $qip-blue-light;
  }
}

.headerMyBalance {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  border: none;
  padding: 10px 20px 10px;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;

  > svg {
    width: 16px;
    height: 16px;
  }

  > span {
    margin-left: 10px;
    font-style: italic;
    font-weight: 900;
    font-size: 24px;
    line-height: 110%;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: $qip-blue;
  }
}

.bankMyBalance {
  margin-top: 40px;
  padding: 16px;
  background: $ui-background;

  > strong {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;
    color: $qip-blue;
  }

  > div {
    margin-top: 8px;
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    color: $qip-blue;

    > b {
      font-weight: bold;
    }
  }

  > a {
    margin-top: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: $ui-blue-system;
  }
}

.balanceAvailable {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  > .balanceAvailableBalance {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: $qip-blue-light;
  }
  
  > .balanceAvailableWithdraw {
    button {
      padding: 0 10px;
    }
  }

  > a {
    margin-top: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-decoration-line: underline;
    color: $ui-blue-link;
  }
}

.balanceFuture {
  
  .balanceFutureBalance {
    margin-top: 12px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: #7D829B;
  }
  
  p {
    text-align: center;
    color: $qip-blue-light;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}

.bankInfoMyBalance {
  margin-top: 40px;
  padding: 16px;
  background: $ui-background;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $qip-blue;

  > strong {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;
    color: $qip-blue;
    margin-bottom: 8px;
  }

  > a {
    margin-top: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-decoration-line: underline;
    color: $ui-blue-link;
  }
}

.detailTransferConfirmationHeader {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: $qip-blue-light;
  height: 36px;
  border-bottom: 1px solid $ui-grey-light;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.detailTransferConfirmation {
  padding: 0 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: #7D829B;
  height: 36px;
  border-bottom: 1px solid $ui-grey-light;


  display: flex;
  align-items: center;
  justify-content: space-between;

}

.detailTransferConfirmationBold {
  font-weight: 900;
  margin-bottom: 20px;
}



.balanceAvailableHeader, .balanceFutureHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding-bottom: 8px;
  margin-top: 20px;
  text-transform: uppercase;
  color: $qip-blue-light;
  border-bottom: 1px solid $ui-grey-light;
}


.confirmBalanceDescription {
  margin-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: $qip-blue;

}

.detailBalanceDescription {
  margin: 16px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: $qip-coral-light;
}

.linkMore {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration-line: underline;
  color: $qip-coral-light;
  margin: 16px 0 24px;
}

.Collapsible {
  margin-top: 40px;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
     p {
       color: $qip-blue-light;
     }
    .fa-angle-down, .fa-angle-up {
      color: $qip-coral;
      font-size: 18px;
    }
    
  }
}
