@import '../../global/vars';

.eqipo-card {
  position: relative;
  display: grid;

  &__status {
    height: 18px;
    width: fit-content;
    padding: 0 5px 1px;
    margin: 5px 0;
    background: $qip-coral-light;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    text-transform: capitalize;
    color: $ui-white;
  }

  &__content {
    overflow: hidden;
  }

  &__grid {
    // height: 203px;
    grid-template-rows: 157px 1fr;
    grid-gap: 4px;
    grid-template-areas: 'image' 'data';

    .eqipo-card__price {
      position: absolute;
      top: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 5px;
      z-index: 2;
      color: $qip-blue;
      background: #fff;
      padding: 3px 4px;
      font-size: 12px;
      font-weight: bold;
    }
    
    .eqipo-card__blur {
      filter: blur(2px);
    }
    
    .eqipo-card__sold {
      position: absolute;
      top: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 5px;
      z-index: 2;
      padding: 3px 4px;
      font-size: 12px;
      font-weight: bold;
      background: $qip-blue;
      color: $ui-white;
    }
  }

  &__list {
    padding: 10px;
    height: 116px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
    grid-template-columns: 96px 1fr;
    grid-template-rows: 100%;
    grid-template-areas: 'image' 'data';
    grid-gap: 4px;
    margin-bottom: 5px;

    .eqipo-card__content {
      display: flex;
      flex-direction: column;
    }

    .eqipo-card__price {
      color: $qip-blue;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 4px;
    }

    .eqipo-card__blur {
      filter: blur(2px);
    }
  }

  &__header {
    grid-area: image;
    position: relative;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $qip-blue;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
  }

  &__seller {
    color: $ui-grey;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    text-transform: capitalize;
  }

  &__price-blur {
    filter: blur(3px);
  }
}
