@import '../../global/vars';

.return-header {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 120%;
    text-transform: uppercase;
    color: $qip-blue-light;
    padding-left: 10px;
  }

  &.return-border {
    border-bottom: solid 1px $ui-grey-light;
  }
}
