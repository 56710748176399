@import '../../global/vars';

.containerResetPassword {
  margin: 48px 20px;

  .headerResetPassword {
    width: 298px;
    font-style: normal;
    font-weight: 900;
    font-size: 31px;
    line-height: 37px;
    text-align: center;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: $qip-blue-light;
    b {
      color: $qip-coral-light;
    }
  }

  form {
    margin-top: 32px;
  }
}

.container .btnSendEmail {
  height: 40px;
  font-size: 12px;
  font-weight: 900;
  border-radius: 40px;
  color: $usefy-white;
  background: $qip-coral;
  width: 100%;
  margin-top: 1rem;
  border: 0;

  &:hover {
    outline: none;
  }
}

.container .btnGoBack {
  height: 2.2rem;
  border-radius: 0.5rem;
  font-size: 1.2em;
  background: $usefy-white;
  color: $usefy-red;
  width: 100%;
  margin-top: 1rem;
  border: 1px solid $usefy-red;

  &:hover {
    outline: none;
  }
}
